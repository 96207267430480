import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <main id="img" className="home-container">
      <Helmet>
        <title>Mario</title>
        <meta property="og:title" content="Mario" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/366bfaf7-43fc-4f9c-8f59-5c1a1d26aca2/9cc1d451-4213-49fb-9891-c6d46058452a?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div id="strecken-container" className="home-container01">
        <div className="home-container02">
          <img
            id="random-strecke"
            alt="image"
            src="/emptycup2-200h.png"
            className="home-image"
          />
        </div>
        <div className="home-container03">
          <span id="strecken-name" className="home-streckenname">
             
          </span>
        </div>
      </div>
      <div id="image-container" className="home-container04">
        <img
          id="random-image"
          alt="image"
          src="/emptychar%20kopie-800w.png"
          className="home-kart"
        />
      </div>
      <div className="home-container05">
        <span id="filename" className="home-text">
          <span> </span>
          <br></br>
        </span>
        <div className="home-container06">
          <div id="show-random-image2" className="home-container07">
            <img
              alt="image"
              src="/streckebutton-200h.png"
              className="home-image1 animate__animated animate__pulse animate__infinite \tinfinite"
            />
          </div>
          <div id="show-random-image" className="home-container08">
            <img
              alt="image"
              src="/setupbutton-200h.png"
              className="home-image2 animate__animated animate__pulse animate__infinite \tinfinite"
            />
          </div>
        </div>
        <img
          alt="image"
          src="/mariokartwii-200h.webp"
          className="home-wiilogo"
        />
      </div>
      <div className="home-container09"></div>
    </main>
  )
}

export default Home
